<nb-card class="dialog-container">
  <nb-card-header>
    <div class="header-text">
      <div class="title">Column Editor</div>
      <div *ngIf="(excludeMode$ | async) === false" class="sub-title">
        Drag and drop columns in the order you'd like to see them in the drill
        down of this widget
      </div>
      <div *ngIf="excludeMode$ | async" class="sub-title">
        Drag and drop columns to exclude them from the drill down of this widget
      </div>
    </div>
    <div class="header-actions">
      <button
        nbButton
        status="success"
        class="action-button"
        (click)="saveAndClose()"
      >
        <nb-icon icon="save-outline"></nb-icon>Save and Close
      </button>
      <resplendent-close-button
        class="action-button"
        (click)="cancel()"
      ></resplendent-close-button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="mode-switch">
      <nb-checkbox
        [checked]="excludeMode$ | async"
        (checkedChange)="toggleMode($event)"
      >
        Exclude Mode
      </nb-checkbox>
    </div>
    <div class="lists-container">
      <div class="list-col">
        <h6>Available Columns</h6>
        <nb-form-field class="column-filter">
          <nb-icon nbPrefix icon="funnel-outline"></nb-icon>
          <input
            type="text"
            nbInput
            placeholder="Filter Columns"
            fieldSize="small"
            (input)="filterList($any($event.target).value)"
          />
        </nb-form-field>
        <div
          cdkDropList
          #availableList="cdkDropList"
          [cdkDropListData]="filteredAvailableColumns"
          [cdkDropListConnectedTo]="[selectedList]"
          class="drop-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="drop-box"
            (click)="toggleColumn(col)"
            cdkDrag
            *ngFor="let col of filteredAvailableColumns"
          >
            {{ columnInfo[col].alias || columnInfo[col].name }}
          </div>
        </div>
      </div>
      <div class="list-col">
        <h6>
          {{ (excludeMode$ | async) ? 'Excluded Columns' : 'Selected Columns' }}
        </h6>
        <nb-checkbox
          class="select-all-checkbox"
          [indeterminate]="
            availableColumns.length !== 0 &&
            (selectedColumns$ | async).length !== 0
          "
          [checked]="availableColumns.length === 0"
          (checkedChange)="toggleAll($event)"
          >{{
            availableColumns.length === 0 ? 'Deselect All' : 'Select All'
          }}</nb-checkbox
        >
        <div
          cdkDropList
          #selectedList="cdkDropList"
          [cdkDropListData]="selectedColumns$ | async"
          [cdkDropListConnectedTo]="[availableList]"
          class="drop-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            cdkDrag
            *ngFor="let col of selectedColumns$ | async"
            (click)="toggleColumn(col)"
            class="drop-box"
          >
            {{ columnInfo[col].alias || columnInfo[col].name }}
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
